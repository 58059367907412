<template>
    <div>
        <div
            :class="full ? 'ren-data-container-full' : 'ren-data-container'"
        >
<!--                    PHOTO SLIDE VIEW-->
            <div v-if="showData==='photos'">
                <b-carousel
                    id="carousel-example-generic"
                    controls
                    :interval="interval"
                    v-model="selectedPhotoIndex"
                    indicators
                    :class="full ? 'ren-photo-container-full' : 'ren-photo-container'"
                >

                    <b-carousel-slide  v-for="(image, index) in images"
                                       :key="'image'+index"
                                        :img-src="image['image_url']"
                                       :class="full ? 'ren-img-container-full' : 'ren-img-container'">

                        <template #default>
                            <div v-if="image.PropertyImageIsEquirectangular === 1" class="position-relative">
                                <a-scene
                                    ref="scene"
                                    embedded
                                    :xr-mode-ui="'enterVRButton: #' + btnId"
                                    @exit-vr="exitFullscreen"
                                >
                                    <a-assets>
                                        <img :id="'sky-' + btnId + index " :src="image['image_url']" alt="vr-image" crossorigin="anonymous">
                                    </a-assets>
                                    <a-sky :src="'#sky-' + btnId + index " rotation="0 -20 0"></a-sky>
                                </a-scene>
                            </div>
                        </template>
                    </b-carousel-slide>
                    <b-carousel-slide
                        :img-src="blankImage"
                        v-if="images.length===0"
                        :class="full ? 'ren-img-container-full' : 'ren-img-container'"
                    />

                </b-carousel>
                <div class="ren-photos-buttons">
                    <b-button v-if="!full" variant="outline-secondary" class="ren-expand-button" size="sm" @click="showModal(images[selectedPhotoIndex])">
                        <rt-icon icon="expand-wide" variant="light" :size="12"/>
                        {{ i18nT(`Plein écran`) }}
                    </b-button>
                    <b-button v-if="full && images.length>0" variant="outline-secondary" class="ren-expand-button" size="lg" @click="showData='grid'">
                        <rt-icon icon="table-cells-large"   variant="light" :size="16"/>
                        {{i18nT(`Grille`)}}
                    </b-button>

                </div>
                <b-button
                      :id="btnId"
                      variant="outline-secondary"
                      class="ren-vr-button"
                      v-show="images.length > 0 && images[selectedPhotoIndex].PropertyImageIsEquirectangular === 1"
                      @click="enterFullScreen"
                      size="sm"
                >
                    <span>
                        <rt-icon icon="vr-cardboard" variant="light" :size="30"/>
                    </span>
                </b-button>
                <div v-if="images.length>0" class="photos-pagination">{{selectedPhotoIndex +1}} {{i18nT(`sur`)}} {{images.length}}</div>

            </div>

<!--                    MAP VIEW-->
            <div v-if="showData==='map'">
                <GmapMap
                    :center='center'
                    :zoom='16'
                    :class="full ? 'ren-map-container-full' : 'ren-map-container'"
                >
                    <GmapMarker
                        :key="'marker'+index"
                        v-for="(m, index) in markers"
                        :position="m.position"
                        @click="center=m.position"
                    />
                </GmapMap>
            </div>

<!--                        STREET VIEW-->
            <div v-if="showData==='street'">
                <GmapStreetViewPanorama
                    :position="center"
                    :pov="{heading: 34, pitch: 10}"
                    :zoom="1"
                    :class="full ? 'ren-map-container-full' : 'ren-map-container'"
                />
            </div>

<!--                    PHOTO GRID VIEW-->
            <div v-if="showData==='grid'">
                <div  class="ren-photos-grid-container-full">
                        <span v-for="(image, index) in images" :key="'image'+index" @click='showPhoto(index)'>
                            <b-img :src="image['image_url']" fluid thumbnail class="ren-grid-img m-50"
                            />
                        </span>
                    <div class="ren-photos-buttons">
                        <b-button v-if="full" variant="outline-secondary" class="ren-expand-button" size="lg"
                                  @click="showData='photos'">
                            <rt-icon icon="gallery-thumbnails" variant="light" :size="16"/>
                            {{ i18nT(`Slide`) }}
                        </b-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center align-items-center mt-1">
            <b-form-radio-group
                v-model="showData"
                buttons
                button-variant="outline-primary"
                size="md"
                class="ren-data-buttons mx-50 text-nowrap"
            >
                <b-form-radio value="photos"><rt-icon icon="camera" variant="light mr-50" size="16"/>{{ i18nT(`Photos`) }}</b-form-radio>
                <b-form-radio value="street"><rt-icon icon="location-dot" variant="light mr-50" size="16"/>{{ i18nT(`Rue`) }}</b-form-radio>
                <b-form-radio value="map"><rt-icon icon="map" variant="light mr-50" size="16"/>{{ i18nT(`Carte`) }}</b-form-radio>
            </b-form-radio-group>
        </div>
    </div>
</template>
<script>
import {
    BButton,
    BCarousel,
    BCarouselSlide,
    BModal,
    VBTooltip
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { gmapApi } from 'vue2-google-maps';
import blankImage from '@/assets/images/pages/no_image.png';
import aframe from 'aframe';

export default{
    components: {
        BModal,
        BCarouselSlide,
        BCarousel,
        BButton,
    },
    directives: {
        'b-tooltip': VBTooltip,
        Ripple,
    },
    props:{
        images:{
            type: Array,
            default: []
        },
        address:{
            type: String,
            default: '',
        },
        full:{
            type: Boolean,
            default: false
        },
        selectedPhoto: {
            type: Object,
            default: null
        },
        btnId: {
            type: String,
            default: ''
        }
    },
    data(){
        return{
            blankImage,
            center: {
                lat: 0 ,
                lng: 0,
            },
            currentPlace: null,
            markers: [],
            showData: 'photos',
            buildingAddress: '',
            selectedPhotoIndex: 0,
            testPhotos: Array.from({length:50}, ()=>({image_url: blankImage})),
            showVrButton: false,
            photoArray: [],
            interval: 3000,
        }
    },
    computed: {
        google: gmapApi
    },
    watch: {
        google: {
            handler: function(newVal){
                if (newVal) {
                    this.buildingAddress = this.address;
                    this.getAddressLocation(this.buildingAddress);
                }
            },
            immediate: true

        },
        address(newVal) {
            if (newVal) {
                this.buildingAddress = newVal;
                this.getAddressLocation(this.buildingAddress);
            }
        },
        selectedPhoto: {
            handler: function(newVal){
                if (newVal) {
                    this.findIndex = this.images.findIndex(image => image.PropertyImageID === newVal.PropertyImageID);
                    this.showPhoto(this.findIndex);
                }
            },
            immediate: true

        },
        full: {
            handler: function(newVal) {
                if (newVal) {
                    this.showData = '';
                    setTimeout(() => {
                        this.showData = 'photos'
                    }, 0);
                }
            },
            immediate: true
        }
    },
    methods:{
        exitFullscreen() {
            this.interval = 3000;
        },
        enterFullScreen(){
            this.interval = 0;
        },
        showPhoto(index){
            this.selectedPhotoIndex = index;
            this.showData = 'photos';
        },
        getAddressLocation (address) {

            if (!this.google) return;

            //get coordinates of the address
            const geocoder = new this.google.maps.Geocoder();
            geocoder.geocode({ address: address }, (results, status) => {
                if (status === this.google.maps.GeocoderStatus.OK) {
                    const location = results[0].geometry.location;
                    const locationCords = {
                        lat: location.lat(),
                        lng: location.lng()
                    };
                    this.center = {...locationCords};
                    this.markers.push({ position: locationCords });
                }
            });
        },
        showModal(photo){
            this.$emit('modal-photos', photo);
        },
    }

}
</script>

<style lang="scss">
.ren-data-container, .ren-photo-container, .ren-img-container {
    height: 550px;
    max-height: 350px;
    width: 100%;
    background-color: #f5f5f5 !important;
    border-radius: 5px;
}
.ren-data-container-full, .ren-photo-container-full, .ren-img-container-full {
    max-height: 70vh;
    max-width: 100%;
    width: 100%;
    background-color: #f5f5f5 !important;
    border-radius: 5px;
}


.ren-img-container-full img {
    object-fit: scale-down;
    height: 70vh;
    width: 100%;
}

.ren-map-container {
    height: 350px;
    width: 100%;
}

.ren-map-container-full{
    max-height: 100%;
    height: 70vh;
    width: 100%;
}

.ren-img-container-full img {
    object-fit: scale-down;
    height: 70vh;
    width: 60%;
}
.ren-img-container img {
    object-fit: scale-down;
    height: 100%;
    width: 60%;
}

.ren-photos-buttons {
    z-index: 1;
    position: absolute;
    top: 16px;
    right: 23px;
    border-radius: 5px;
    background-color: #f5f5f5;
}

.ren-expand-button{
    border: none !important;
}



.ren-vr-button{
    z-index: 1;
    bottom: 100px;
    right: 45px;
    position: absolute;
    border-radius: 5px;
    background-color: #f5f5f5;
    padding: 5px;
    font-size:  12.6px;
    border: none !important;
}

.ren-vr-button-full{
    z-index: 1;
    bottom: -100px;
    right: 45px;
    position: absolute;
    border-radius: 5px;
    background-color: #f5f5f5;
    padding: 5px;
    font-size:  12.6px;
    border: none !important;
}


.ren-img-container {
    transform: translate3d(0, 0, 0);
}



.carousel-control-next-icon{
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23666666' viewBox='0 0 8 8'%3e%3cpath d='M1 0h3l3 3-3 3H1l3-3z'/%3e%3c/svg%3e");
}
.carousel-control-prev-icon{
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23666666' viewBox='0 0 8 8'%3e%3cpath d='M7 0H4l-3 3 3 3h3L4 3z'/%3e%3c/svg%3e");
}


.ren-photos-grid-container-full{
    display: flex;
    flex-grow: inherit;
    //max-height: 70vh;
    max-height: 100%;
    height: 70vh;
    width: 100%;
    flex-wrap: wrap;
    overflow: auto;
}

.ren-grid-img{
    border-radius: 5px;
    cursor: pointer;
    //max-height: 150px;
    //max-width: 200px;
    //min-height: 150px;
    //min-width: 200px;

    max-height: 250px;
    max-width: 300px;
    height: 250px;
    width: 300px;
}

.ren-single-photo{
    height: 70vh;
    width: 60%;
    object-fit: scale-down;
}

.photos-pagination{
    z-index: 1;
    position: absolute;
    bottom: 65px;
    right: 45px;
    border-radius: 5px;
    background-color: #f5f5f5;
    padding: 5px;
    font-size:  12.6px;
    font-weight: 500;
    color: #989b9e;
}

.dark-layout .ren-photos-buttons,.dark-layout .photos-pagination, .dark-layout .ren-vr-button{
    background-color: #161d31;
    color: #808489;
}

.dark-layout .ren-grid-img{
    background-color:#161d31;
    border: 1px solid #808489;
}

.dark-layout .ren-data-container, .dark-layout .ren-photo-container, .dark-layout .ren-img-container,
.dark-layout .ren-data-container-full, .dark-layout .ren-photo-container-full, .dark-layout .ren-img-container-full{
    background-color: #161d31 !important;
}


</style>
